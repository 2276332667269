import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AgmCoreModule } from "@agm/core";
import { AgmDirectionModule } from "agm-direction";
import { DetailViewModule } from "../../../components/detail-view/detail-view.module";

@NgModule({
  declarations: [],
  imports: [CommonModule, AgmCoreModule, AgmDirectionModule, DetailViewModule],
  exports: [],
  entryComponents: [],
})
export class JobDetailsModule {}
